export const environment = {
  production: false,
  apiUrl: 'https://exp-api.getcamber.com/exp-multiplexer-cp-dev-launch2020/proterra/controlpanel/v1/',
  region: 'us-east-1',
  identityPoolId: 'us-east-1:60daf6df-dfce-4b3d-8771-1a387a633ef7',
  userPoolId: 'us-east-1_ZDBgvNtl2',
  clientId: 'ss03f8ht584p85qhbl4c73hrg',
  cognito_idp_endpoint: 'https://cognito-idp.us-east-1.amazonaws.com/us-east-1_ZDBgvNtl2',
  cognito_identity_endpoint: '',
  sts_endpoint: ''
};
